import React, { Component, Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider, Consumer } from "./components/Context";
import {
  LogoutRoute,
  ProtectedRouteAdmin
} from "./components/functions/protectedRoute";
import jwt_decode from "jwt-decode";
import axios from "axios";
import setAuthToken from "./components/functions/setAuthToken";

import "./App.css";

// import RegisterPage from "./components/Register";
// import PaymentPage from "./components/PaymentPage";
// import Bill from "./components/Bill";

// import Users from "./components/Auth/Users";
// import AddUser from "./components/Auth/AddUser";
// import EditUser from "./components/Auth/EditUser";
// import ForgotPassword from "./components/Auth/ForgotPassword";
// import Login from "./components/Auth/Login";

const loading = () => (
  <div className='animated fadeIn pt-3 text-center'>Loading...</div>
);

const Home = React.lazy(() => import("./components/Home"));
const CourseList = React.lazy(() => import("./components/CourseList"));
const Verify = React.lazy(() => import("./components/Verify"));
const HeaderAdmin = React.lazy(() => import("./components/HeaderAdmin"));
const AboutPage = React.lazy(() => import("./components/AboutPage"));
const NotFound = React.lazy(() => import("./components/NotFound"));

const PrintLayout = React.lazy(() => import("./layout/PrintLayout"));
const Login = React.lazy(() => import("./components/Auth/Login"));
const AdminDashboard = React.lazy(() =>
  import("./components/Auth/AdminDashboard")
);
const EditCertificate = React.lazy(() =>
  import("./components/Auth/EditCertificate")
);
const ChangePassword = React.lazy(() =>
  import("./components/Auth/ChangePassword")
);
const AddCertificate = React.lazy(() =>
  import("./components/Auth/AddCertificate")
);

class App extends Component {
  state = {
    isLoggedIn: false
  };

  componentDidMount = () => {
    if (sessionStorage.getItem("jwtToken") !== "undefined") {
      this.setState({ isLoggedIn: sessionStorage.getItem("jwtToken") });
    }
  };

  checkLogin = dispatch => {
    let token = sessionStorage.getItem("jwtToken");
    if (token) {
      let userEmail = sessionStorage.getItem("userEmail");
      setAuthToken(token);
      let decoded = jwt_decode(token);
      axios
        .post(process.env.REACT_APP_API_URL + "/auth/type", { id: decoded.id })
        .then(res => {
          decoded = {
            ...decoded,
            email: userEmail,
            type: res.data.data[0].userType
          };
          dispatch({
            type: "SET_CURRENT_USER",
            payload: decoded
          });
          this.setState({ isLoggedIn: false });
        });
    }
  };

  render() {
    return (
      <Provider>
        <Consumer>
          {value => {
            if (this.state.isLoggedIn) {
              this.checkLogin(value.dispatch);
            }
            return (
              <Router>
                <Suspense fallback={loading()}>
                  <div className='App'>
                    {value.isAuthenticated ? <HeaderAdmin /> : <div />}

                    <Switch>
                      <LogoutRoute exact path='/' component={Home} />
                      <LogoutRoute
                        exact
                        path='/courses'
                        component={CourseList}
                      />
                      <LogoutRoute exact path='/about' component={AboutPage} />
                      {/* <LogoutRoute
                      exact
                      path='/register'
                      component={RegisterPage}
                    /> */}
                      {/* <LogoutRoute
                      exact
                      path='/payment'
                      component={PaymentPage}
                    /> */}
                      {/* <LogoutRoute exact path='/bill' component={Bill} /> */}
                      <LogoutRoute exact path='/verify' component={Verify} />
                      <LogoutRoute exact path='/login' component={Login} />
                      <ProtectedRouteAdmin
                        exact
                        path='/dashboard'
                        component={AdminDashboard}
                      />
                      <ProtectedRouteAdmin
                        exact
                        path='/new-certificate'
                        component={AddCertificate}
                      />
                      <ProtectedRouteAdmin
                        exact
                        path='/change-password'
                        component={ChangePassword}
                      />
                      <ProtectedRouteAdmin
                        exact
                        path='/edit-certificate'
                        component={EditCertificate}
                      />
                      {/* <ProtectedRouteAdmin
                      exact
                      path='/users'
                      component={Users}
                    />
                    <ProtectedRouteAdmin
                      exact
                      path='/new-user'
                      component={AddUser}
                    />
                    <ProtectedRouteAdmin
                      exact
                      path='/edit-user'
                      component={EditUser}
                    /> */}
                      <ProtectedRouteAdmin
                        exact
                        path='/print-certificate'
                        component={PrintLayout}
                      />
                      {/* <LogoutRoute
                      exact
                      path='/forgot-password'
                      component={ForgotPassword}
                    /> */}
                      <Route component={NotFound} />
                    </Switch>
                  </div>
                </Suspense>
              </Router>
            );
          }}
        </Consumer>
      </Provider>
    );
  }
}

export default App;
