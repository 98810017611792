import React from "react";
import { Route, Redirect } from "react-router-dom";
import { Consumer } from "../Context";

export const ProtectedRouteAdmin = ({ component: Component, ...rest }) => {
  return (
    <Consumer>
      {value => {
        return (
          <Route
            {...rest}
            render={props => {
              if (value.isAuthenticated && value.user.type === "Admin") {
                return <Component {...props} />;
              } else {
                return (
                  <Redirect
                    to={{
                      pathname: "/login",
                      state: {
                        from: props.location
                      }
                    }}
                  />
                );
              }
            }}
          />
        );
      }}
    </Consumer>
  );
};

export const LogoutRoute = ({ component: Component, ...rest }) => {
  return (
    <Consumer>
      {value => {
        return (
          <Route
            {...rest}
            render={props => {
              if (!value.isAuthenticated) {
                return <Component {...props} />;
              } else {
                if (value.user.type === "Admin") {
                  return (
                    <Redirect
                      to={{
                        pathname: "/dashboard", //change later
                        state: {
                          from: props.location
                        }
                      }}
                    />
                  );
                }
              }
            }}
          />
        );
      }}
    </Consumer>
  );
};
